<template>
  <div ref="projectlist">
    <SEARCH :list="searchArr"></SEARCH>
    <TABS :list="tabsArr"></TABS>
    <TABLE :list="tableArr" :data="dataArr"></TABLE>
    <PAGE :count="count"></PAGE>
  </div>
</template>

<script>
import SEARCH from '@/components/search' // 顶部搜索
import TABS from '@/components/tabs' // 横向 button
import TABLE from '@/components/table' // 列表
import PAGE from '@/components/page' // 分页
import { getUser } from '@/api/user'
export default {
  name: 'userList',
  components: {
    SEARCH,
    TABS,
    TABLE,
    PAGE,
  },
  data() {
    return {
      searchArr: [
        {
          name: '姓名',
          type: 'input',
          options: [],
          key: 'name',
        },
        {
          name: '账户',
          type: 'input',
          options: [],
          key: 'username',
        },
        {
          name: '手机号',
          type: 'input',
          key: 'phoneNumber',
        },
        {
          name: '所属单位',
          type: 'picker',
          key: 'projectUnit',
        },
        {
          name: '职务',
          type: 'picker',
          key: 'userPosition',
        },
      ], // 搜索列表
      tabsArr: [
        {
          type: 'primary',
          name: '新增用户',
          func: '',
        },
        {
          type: 'primary',
          name: '导入用户',
          func: '',
        },
        {
          type: 'primary',
          name: '下载模板',
          func: '',
        },
        {
          type: 'primary',
          name: '导出用户',
          func: '',
        },
        {
          type: 'danger',
          name: '批量删除',
          func: '',
        },
      ], // 标签列表
      tableArr: [
        {
          prop: 'serial',
          label: '姓名',
          width: '180',
        },
        {
          prop: 'nickname',
          label: '账户',
          width: '180',
        },
        {
          prop: 'start_time',
          label: '单位',
          width: '140',
        },
        {
          prop: 'start_time',
          label: '分组',
          width: '140',
        },
        {
          prop: 'remarks',
          label: '职务',
          width: '180',
        },
        {
          prop: 'start_time',
          label: '手机号',
          width: '300',
        },
        {
          prop: '',
          label: '操作',
          width: '',
          but: true,
        },
      ], // 表头列表
      dataArr: [], // 数据列表
      butnArr: [
        {
          type: '',
          name: '编辑',
          func: '',
        },
        {
          type: '',
          name: '密码',
          func: '',
        },
        {
          type: '',
          name: '授权',
          func: '',
        },
        {
          type: 'danger',
          name: '删除',
          func: '',
        },
      ], // 数据-操作列表
      count: 0, // 总页数
      formData: {
        page: 1,
        limit: 10,
        type: '',
        name: '',
        unit_id: '',
        create_at: '',
      },
    }
  },
  created() {},
  mounted() {
    this._loadData()
  },
  methods: {
    _loadData: function () {
      this.formData.unit_id = this.$store.getters.USER_ID
      getUser(this.formData).then((res) => {
        console.log(res, '这是用户管理')
        const { result } = res

        this.count = result.count
        result.data.forEach(item => {
          item.buttons = this.butnArr
        })
        this.dataArr = result.data
      })
    },
  },
}
</script>

<style lang="stylus" scoped></style>
